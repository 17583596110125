import React, { useState } from 'react'
import { Organization } from '../../reducers/organizationReducer'
import { useHistory } from 'react-router-dom'
import { useAxiosWithHeader } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { PlayerState } from '../../reducers/playersReducer'
import OnboardingSteps from '../../components/OnboardingSteps'
import LoaderIcon from '../../components/LoaderIcon'
import { Typography } from 'antd'
import { MainDivOnboarding } from '../../components/OnboardingSteps/main-div'
import gitlab from '../../assets/images/gitlab-logo-500.png'
import { GitLabAdmin } from './GitLabAdmin'
import { User } from '../../reducers/userReducer'
import messageInteraction from '../../components/Analytics/utils/messageInteraction'

export default function GitLabGroups({ autoReviewRequestOnboarding }) {
  const axios = useAxiosWithHeader()
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(User.selectors.selectUser)
  const [isEnabling, setIsEnabling] = useState(false)

  const [nameSpaces, setNameSpaces] = useState(false)
  const [loader, setLoader] = useState({
    loading: true,
    text: 'Getting your gitlab organizations..',
  })

  const fetchOrgText = 'Fetching GitLab organizations, please wait.'
  const createOrgText =
    'Creating your organization, fetching players, repositories, and merge requests. This process can take up to 2 minutes.'

  useEffectOnce(() => {
    setLoader({ loading: true, text: fetchOrgText })

    axios
      .get(
        `/gitlab/getNameSpaces?autoReviewRequestOnboarding=${autoReviewRequestOnboarding}`,
      )
      .then((resp) => {
        setNameSpaces(resp.data)
        if (resp.data.length === 0) {
          setLoader({
            loading: false,
            text: "Didn't find any organizations, ask your admin to install or contact us for support.",
          })
        }
        setLoader({ loading: false, text: '' })
      })
      .catch((resp) => {
        if (resp.message.includes('409')) {
          setLoader({
            loading: false,
            text: `Error: You are already assigned to an organization. Contact support or onboard your team.`,
          })
        } else {
          setLoader({ loading: false, text: `Error ${resp.message}` })
        }
      })
  })

  async function selectGroup(id) {
    setLoader({ loading: true, text: createOrgText })
    try {
      const groupResp = await axios.get(
        `/gitlab/onboardingGitlab/${id}?autoAssignReviewerSignIn=${autoReviewRequestOnboarding}`,
      )
      const {
        organization,
        slackMembers,
        pullRequests,
        setting,
        paymentPlan,
        teams,
        players,
        user,
      } = groupResp.data
      dispatch(
        Organization.actions.setData({
          ...organization,
          teams,
          slackMembers,
          pullRequests,
          setting,
          paymentPlan,
        }),
      )
      dispatch(PlayerState.actions.setInitialPlayers({ players }))
      dispatch(User.actions.updateUser(user))
      if (autoReviewRequestOnboarding) {
        history.push('/settings/auto-assign-reviewer')
      } else {
        history.push('/home/onboarding/members')
      }
    } catch (error) {
      console.log('error fetching gitlab organization', error)
      setLoader({
        loading: false,
        text: 'Error creating your organization, try again or contact support.',
      })
    }
  }

  async function enableSubgroup() {
    setIsEnabling(true) // Set loading state
    try {
      const response = await axios.post('/credentials/enable-subgroup')
      if (response.status === 200) {
        dispatch(
          User.actions.updateUser({
            credential: {
              ...user.credential,
              allowSubGroup: true,
            },
          }),
        )
      }
      // Re-fetch GitLab subgroups after enabling
      const resp = await axios.get(
        `/gitlab/getNameSpaces?autoReviewRequestOnboarding=${autoReviewRequestOnboarding}`,
      )
      setNameSpaces(resp.data)
      setLoader({ loading: false, text: 'Subgroup enabled successfully!' }) // Success message
    } catch (error) {
      console.error('Error enabling subgroup:', error)
      let errorMessage = 'Error enabling subgroup, please try again.'

      // Check for specific error responses
      if (error.response) {
        if (error.response.status === 403) {
          errorMessage =
            'The server returned a 403 error while trying to enable this subgroup.'
        } else {
          errorMessage = error.response.data?.message || errorMessage
        }
      }

      messageInteraction({
        type: 'error',
        content: errorMessage,
      })

      setLoader({
        loading: false,
        text: errorMessage,
      })
    } finally {
      setIsEnabling(false) // Reset loading state
    }
  }

  const nameSpacesFiltered = nameSpaces
    ? nameSpaces.filter(
        (namespace) =>
          !user.organizations.some((org) => org.providerLogin === namespace.path),
      )
    : []

  return (
    <MainDivOnboarding>
      {!autoReviewRequestOnboarding && <OnboardingSteps step={2} />}
      <br></br>
      <div className="m-10 flex flex-col justify-center text-center">
        <Typography.Title level={4}>Choose a GitLab account</Typography.Title>
      </div>
      {nameSpaces.length === 0 && !loader.loading && (
        <div className="flex flex-col">
          <p className="mt-5 text-center text-slate-700">
            It seems you are not the owner of any groups
            {user.credential.allowSubGroup === true ? ' or subgroups' : ''},<br></br>
            ask your admin to add you as an owner to your group to install Axolo
            <br></br>
            or ask your admin to Install Axolo.
            <br></br>
            <br></br>
            Refresh this page to reload.
          </p>
          {user.credential.allowSubGroup === false && (
            <div className="mt-4 flex flex-col items-center text-slate-700">
              <span className="">
                Interested in installing Axolo on a GitLab subgroup?
              </span>
              <button
                className="mt-2 w-48 rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-base font-medium text-white hover:bg-indigo-700"
                onClick={enableSubgroup}
                disabled={isEnabling}
              >
                <span className="text-sm">
                  {isEnabling ? 'Enabling...' : 'Enable Subgroup'}
                </span>
              </button>
            </div>
          )}
        </div>
      )}
      {!nameSpaces || (loader.loading && !loader?.text?.includes('Error')) ? (
        <div className="flex flex-col justify-center">
          <LoaderIcon title={loader.text} spinning={loader?.loading} />
          {loader?.text?.includes('You are already assigned to an organization') && (
            <div className="flex justify-center">
              <br></br>
              <button
                className="m-1 rounded-sm bg-primary px-2 py-1 text-textWhite hover:bg-hoverPrimary hover:text-textWhite disabled:cursor-not-allowed disabled:opacity-50"
                onClick={() => {
                  history.push('/home/onboarding/members')
                }}
              >
                Onboard my team
              </button>
            </div>
          )}
        </div>
      ) : (
        <ul className="divide-y divide-gray-200">
          {nameSpacesFiltered.map((group) => {
            let avatar_url = group?.avatar_url?.includes('https://')
              ? group?.avatar_url
              : `https://gitlab.com${group?.avatar_url}`
            if (group.avatar_url === null) {
              avatar_url = gitlab
            }
            return (
              <li key={group.id}>
                <div
                  href={'#'}
                  onClick={() => selectGroup(group.id)}
                  className="block cursor-pointer hover:bg-gray-50"
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">
                      <div className="flex-shrink-0">
                        {group?.avatar_url === undefined ? (
                          <img className="h-12 w-12 rounded-full" src={gitlab} alt="" />
                        ) : (
                          <img
                            className="h-12 w-12 rounded-full"
                            src={avatar_url}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {group.name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <span className="truncate">{group.full_path}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
      {!loader.loading && loader.text !== createOrgText && (
        <GitLabAdmin
          autoReviewRequestOnboarding={autoReviewRequestOnboarding}
        ></GitLabAdmin>
      )}
    </MainDivOnboarding>
  )
}
