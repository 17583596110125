import 'styled-components/macro'
import React, { useRef, useState } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Input, message, Button, Tooltip } from 'antd'
import { cloneDeep } from 'lodash'
import { Organization } from '../../reducers/organizationReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useAxiosWithHeader } from '../../utils'
import { User } from '../../reducers/userReducer'
const slugify = require('slugify')

export default function ChannelTitle({ organization, setting }) {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const axios = useAxiosWithHeader()
  const user = useSelector(User.selectors.selectUser)
  const [value, setValue] = useState(organization.setting.channelTitle)
  const [validation, setValidation] = useState({ status: '', message: '' })
  const { provider, pullRequests } = organization
  const pull = provider === 'github' ? 'pull' : 'merge'
  async function updateChannelTitle(channelTitle) {
    if (channelTitle?.length > 90) {
      setValidation({ status: 'error', message: '90 chars maximum.' })
      return
    }
    const tags = [
      '[pull_request_title]',
      '[branch_name]',
      '[target_branch]',
      '[pull_request_number]',
      '[repository_name]',
    ]
    const checkDuplicate = tags
      .map(function (e) {
        return channelTitle.indexOf(e, channelTitle.indexOf(e) + 1) !== -1
      })
      .includes(true)

    if (checkDuplicate) {
      setValidation({ status: 'warning', message: 'Duplicate tags.' })
      return
    }
    if (
      !channelTitle.includes('[pull_request_title]') &&
      !channelTitle.includes('[pull_request_number]')
    ) {
      setValidation({
        status: 'warning',
        message: 'Must include [pull_request_title] or the [pull_request_number].',
      })
      return
    }
    try {
      await axios.put(`/settings/${setting._id}`, {
        channelTitle,
      })
      message.success(`Your title ${channelTitle} has been saved.`)
      let newSettings = cloneDeep(setting)
      newSettings.channelTitle = channelTitle
      dispatch(Organization.actions.setData({ setting: newSettings }))
    } catch (error) {
      setValidation({
        status: 'error',
        message: `Couldn't update channel title ${error}`,
      })
    }
  }

  function isTagAlreadyIncluded(tag) {
    return value?.includes(tag) || false
  }

  function onChange(e) {
    const { value } = e.target
    if (value.length > 90) {
      setValidation({ status: 'error', message: '90 chars maximum.' })
      return
    }
    const tags = [
      '[pull_request_title]',
      '[branch_name]',
      '[org]',
      '[target_branch]',
      '[pull_request_number]',
      '[repository_name]',
      '[status]',
    ]
    const checkDuplicate = tags
      .map(function (e) {
        return value.indexOf(e, value.indexOf(e) + 1) !== -1
      })
      .includes(true)
    if (checkDuplicate) {
      setValidation({ status: 'warning', message: 'Duplicate tags.' })
    }
    setValidation({ status: '', message: '' })
    setValue(value)
  }

  function insertTag(tag) {
    console.log('tag', tag)
    if (value?.includes(tag)) {
      setValidation({
        status: 'warning',
        message: `${tag} is already in your channel title.`,
      })
      return
    }
    var index = inputRef?.current?.input?.selectionStart
    if (index || index === 0) {
      setValue(value.slice(0, index) + '_' + tag + value.slice(index + 1))
      updateChannelTitle(value.slice(0, index) + '_' + tag + value.slice(index + 1))
    } else {
      console.log('value + tag', value + tag)
      setValue(value + tag)
      updateChannelTitle(value + tag)
    }
  }

  function example() {
    let {
      title = 'pr_title',
      ref = 'branch',
      baseRef = 'main',
      data = { head: { ref: 'branch_name' } },
      number = 434,
      status = 'draft',
    } = {}
    if (pullRequests && pullRequests?.length > 0) {
      ;({ title, data, number, status } = pullRequests[0])
    }
    const repo = pullRequests[0]?.repository
    let channelTitle = value
    channelTitle = channelTitle?.replace('[pull_request_title]', title || 'pr_title')
    channelTitle = channelTitle?.replace('[branch_name]', ref || 'example_branch')
    channelTitle = channelTitle?.replace('[target_branch]', baseRef || 'example_branch')
    channelTitle = channelTitle?.replace(
      '[pull_request_number]',
      number?.toString() || '123',
    )
    channelTitle = channelTitle?.replace(
      '[repository_name]',
      repo?.name || 'example_repository_name',
    )
    channelTitle = channelTitle?.replace(
      '[creator_name]',
      pullRequests[0]?.creator?.providerLogin || 'johndoe',
    )
    channelTitle = channelTitle?.replace('[status]', status || 'draft') || ''
    channelTitle = slugify(channelTitle, { replacement: '_', trim: false })
    channelTitle = channelTitle?.replace(/[^\w\s-]/g, '_')
    return channelTitle
  }

  function resetValue() {
    const initialValue = '_pr_[repository_name]_[pull_request_title]'
    setValue(initialValue)
    updateChannelTitle(initialValue)
  }

  const TagIconDiv = ({ children }) => {
    return <div className="mr-1 inline-flex justify-center ">{children}</div>
  }

  return (
    <>
      <div className="mt-8">
        <div className="flex flex-col justify-between">
          <div className="flex flex-row justify-between">
            <p className="mb-0 flex">
              Customize the {pull} request Slack channel title of your team.
            </p>
            <div style={{ minWidth: 550 }}>
              <div className="flex">
                <Input
                  status={validation.status}
                  onBlur={() => {
                    updateChannelTitle(value)
                  }}
                  ref={inputRef}
                  value={value}
                  onChange={(e) => onChange(e)}
                />
                <Button onClick={resetValue} className="ml-2">
                  Reset
                </Button>
              </div>
              {pullRequests?.length > 0 ? (
                <p className="font-medium text-gray-500">Example: {example()}</p>
              ) : null}
              {validation.status === 'warning' ? (
                <p className="absolute font-extralight text-yellow-800">
                  {validation.message}
                </p>
              ) : (
                <p className="absolute font-extralight text-red-400">
                  {validation.message}
                </p>
              )}
            </div>
          </div>
          <br></br>
          <div className="flex flex-col items-end">
            <p>Click on the variable you want to add:</p>
            <div>
              <Button
                disabled={isTagAlreadyIncluded('[pull_request_title]')}
                className="cursor-pointer"
                size="small"
                onClick={() => insertTag('[pull_request_title]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Pull Request Title
              </Button>
              <Tooltip title="If a PR is draft, merged, or closed, Axolo will write it, otherwise nothing will appear.">
                <Button
                  className="!ml-1 inline-grid"
                  size={'small'}
                  disabled={isTagAlreadyIncluded('[draft]')}
                  onClick={() => insertTag('[draft]')}
                  icon={
                    <TagIconDiv>
                      <PlusCircleOutlined />
                    </TagIconDiv>
                  }
                >
                  Draft, Merged, Closed
                </Button>
              </Tooltip>
              {user?.organizations?.length > 1 ? (
                <Button
                  disabled={isTagAlreadyIncluded('[org]')}
                  className="!ml-1 cursor-pointer"
                  size="small"
                  onClick={() => insertTag('[org]')}
                  icon={
                    <TagIconDiv>
                      <PlusCircleOutlined />
                    </TagIconDiv>
                  }
                >
                  Organization
                </Button>
              ) : null}
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[branch_name]')}
                onClick={() => insertTag('[branch_name]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Branch Name
              </Button>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[target_branch]')}
                onClick={() => insertTag('[target_branch]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Target Branch
              </Button>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[pull_request_number]')}
                onClick={() => insertTag('[pull_request_number]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Pull Request Number
              </Button>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[repository_name]')}
                onClick={() => insertTag('[repository_name]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Repository Name
              </Button>
              <Button
                className="!ml-1 inline-grid"
                size={'small'}
                disabled={isTagAlreadyIncluded('[creator_name]')}
                onClick={() => insertTag('[creator_name]')}
                icon={
                  <TagIconDiv>
                    <PlusCircleOutlined />
                  </TagIconDiv>
                }
              >
                Creator Name
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
