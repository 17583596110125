import { professionalPricingIds, businessPricingIds } from '../../assets/stripeKeys'
import moment from 'moment'
import { useAxiosWithHeader } from '../../utils'
import { useHistory } from 'react-router-dom'
import { InitCheckoutSession, RedirectToPortal } from '../Stripe/utils'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ButtonCss = ({ danger, inactive, children, onClickAction }) => {
  return (
    <button
      onClick={() => onClickAction()}
      className={classNames(
        'mt-8 block w-full rounded-md border border-primary bg-primary py-2 text-center text-sm font-semibold text-white hover:bg-blue-700 hover:text-white',
        danger && 'border-red-500 bg-red-500 hover:bg-red-700 hover:text-white',
        inactive &&
          'cursor-default border-gray-300 bg-gray-300 text-gray-800 hover:bg-gray-300',
      )}
    >
      {children}
    </button>
  )
}

export const ButtonBilling = ({ tier, organization, players, monthlySelected }) => {
  const activePlayers = players?.filter(
    (player) => player.communicationToolId?.length > 0,
  )?.length
  const plan = organization?.plan
  const axios = useAxiosWithHeader()
  const trialDayRemaining = moment(organization?.createdAt)
    .add('14', 'days')
    .diff(moment(), 'days')
  const history = useHistory()

  const TierFree = () => {
    if (!plan || plan === 'starter')
      return <ButtonCss inactive={true}>Current plan ✅</ButtonCss>
    else if (
      plan === 'professional' ||
      plan === 'blocked' ||
      plan === 'business' ||
      plan === 'enterprise'
    ) {
      return (
        <ButtonCss
          danger={true}
          onClickAction={() => {
            window.$crisp.push(['do', 'chat:open'])
            window.$crisp.push([
              'set',
              'message:text',
              ['I want to downgrade my plan on Axolo.'],
            ])
          }}
        >
          Downgrade (50 PR/month limit)
        </ButtonCss>
      )
    } else {
      return <ButtonCss inactive={true}>{trialDayRemaining} days left in trial</ButtonCss>
    }
  }

  const TierStandard = () => {
    if (!plan) {
      return (
        <ButtonCss onClickAction={() => history.push('/home')}>
          Complete your onboarding
        </ButtonCss>
      )
    } else if (plan === 'business' || plan === 'enterprise') {
      return (
        <ButtonCss
          danger={true}
          onClickAction={() => {
            window.$crisp.push(['do', 'chat:open'])
          }}
        >
          Downgrade
        </ButtonCss>
      )
    } else if (plan === 'starter' || plan === 'blocked')
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? professionalPricingIds.monthly
                : professionalPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade
        </ButtonCss>
      )
    else if (plan === 'professional') {
      return (
        <ButtonCss onClickAction={() => RedirectToPortal({ axios })}>
          Current plan ✅
        </ButtonCss>
      )
    } else if (plan === 'trial') {
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? professionalPricingIds.monthly
                : professionalPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade free trial
        </ButtonCss>
      )
    }
  }

  const TierBusiness = () => {
    if (!plan) {
      return (
        <ButtonCss onClickAction={() => history.push('/home')}>
          Complete your onboarding
        </ButtonCss>
      )
    } else if (plan === 'starter' || plan === 'blocked' || plan === 'professional')
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? businessPricingIds.monthly
                : businessPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade
        </ButtonCss>
      )
    else if (plan === 'business') {
      return (
        <ButtonCss onClickAction={() => RedirectToPortal({ axios })}>
          Current plan ✅
        </ButtonCss>
      )
    } else if (plan === 'enterprise') {
      return (
        <ButtonCss
          danger={true}
          onClickAction={() => {
            window.$crisp.push(['do', 'chat:open'])
          }}
        >
          Downgrade
        </ButtonCss>
      )
    } else if (plan === 'trial') {
      return (
        <ButtonCss
          onClickAction={() =>
            InitCheckoutSession({
              priceId: monthlySelected
                ? businessPricingIds.monthly
                : businessPricingIds.yearly,
              quantity: activePlayers,
              axios,
            })
          }
        >
          Upgrade free trial
        </ButtonCss>
      )
    }
  }

  if (tier.name === 'Business') {
    return <TierBusiness />
  } else if (tier.name === 'Free') {
    return <TierFree />
  } else if (tier.name === 'Standard') {
    return <TierStandard />
  } else if (tier.name === 'Enterprise' && plan === 'enterprise') {
    return <ButtonCss>Current plan ✅</ButtonCss>
  } else if (tier.name === 'Enterprise' && plan !== 'enterprise') {
    return (
      <a
        href={tier.href}
        target="_blanck"
        rel="noopener noreferrer nofollow"
        className={classNames(
          'mt-8 block w-full rounded-md border border-primary bg-primary py-2 text-center text-sm font-semibold text-white hover:bg-blue-700 hover:text-white',
        )}
      >
        {tier.buttonText}
      </a>
    )
  }
}

export const EndButton = ({ hasPayments, axios }) => {
  // async function RedirectToPortal({ axios }) {
  //   const resp = await axios.get(`${URLBACK}stripe/customerPortal`);
  //   window.location.href = resp.data.url;
  //   window.analytics.track('redirectToPortal');
  // }
  if (hasPayments) {
    return (
      <button
        className="mt-8 block rounded-md border border-primary bg-primary px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-700 hover:text-white"
        onClick={() => RedirectToPortal({ axios })}
      >
        Manage subscription
      </button>
    )
  } else {
    return (
      <a
        rel="noreferrer"
        className="mt-8 block rounded-md border border-primary bg-primary px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-700 hover:text-white"
        href="https://axolo.co/pricing"
        target="_blank"
      >
        More info
      </a>
    )
  }
}
