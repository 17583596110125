import React from 'react'
import { Popconfirm, message } from 'antd'
import { IntegrationButton } from '../IntegrationButton'
// import { SentryIntegration } from '../SentryIntegration'
import gitlab from '../../assets/images/gitlab-logo-500.png'
import { URLBACK } from '../../assets/urls'
import { goGithubApp, updateGitLabHook } from '../Helpers'
import { classNames, useAxiosWithHeader } from '../../utils'
import messageInteraction from '../Analytics/utils/messageInteraction'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../../reducers/userReducer'
import { handleLogout } from '../logoutButton/handleLogout'
import GATTokenInput from './AddGAT'

export const OtherIntegrations = ({ user, organization }) => {
  const axios = useAxiosWithHeader()
  const { provider = 'github' } = organization
  const dispatch = useDispatch()
  const jwt = useSelector(User.selectors.selectJWT)

  async function onDelete() {
    const hide = messageInteraction({
      content: 'Deleting your organization...',
      type: 'loading',
      duration: 0,
    })
    await axios.get(`${URLBACK}organizations/deleteEverything/user/${organization.id}`)
    hide()
    messageInteraction({
      content: 'Your organization has been deleted',
      type: 'success',
    })
    handleLogout(jwt, dispatch, axios)
  }

  let githubCredentialCopy = Object.assign({}, user?.githubCredential)

  if (user.githubCredential?.type === 'githubSelfHosted') {
    githubCredentialCopy.githubDomain = githubCredentialCopy.githubHost
    githubCredentialCopy.githubAppName = githubCredentialCopy.appName
  }
  const JiraCred =
    organization?.credentials?.filter((cred) => cred?.tool === 'jira').length > 0
  return (
    <>
      <div
        className={classNames(
          'mb-12 grid grid-cols-2 justify-items-center gap-4',
          '!grid-cols-3',
        )}
      >
        <div>
          {provider === 'github' ? (
            <div
              className="block h-20 justify-center justify-self-center text-center "
              onClick={() =>
                goGithubApp(user.id, user.providerAuth, githubCredentialCopy)
              }
            >
              <IntegrationButton
                imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/github_logo.png"
                text="Update GitHub app"
              />
            </div>
          ) : (
            <>
              <div>
                <div
                  className="block h-20 justify-center justify-self-center text-center "
                  onClick={() =>
                    updateGitLabHook({ organizationId: organization.id, axios })
                  }
                >
                  <IntegrationButton imageSrc={gitlab} text="Update GitLab Webhooks" />
                </div>

                <Popconfirm
                  title={
                    <p>
                      Are you sure to delete all your data? <br></br> You will be logged
                      out, lose all your data on Axolo and your organization will be
                      deleted.
                    </p>
                  }
                  onConfirm={() => onDelete()}
                  okText="Yes"
                  cancelText="No"
                >
                  <a
                    href={
                      'https://help.axolo.co/en/article/how-to-delete-all-my-data-on-axolo-1tg8xq9/'
                    }
                    target="_blanck"
                    rel="noopener noreferrer nofollow"
                    className={classNames(
                      'mt-20 block w-full rounded-md border border-red-500 bg-red-500 py-2 text-center text-sm font-semibold text-white hover:bg-red-700 hover:text-white',
                    )}
                  >
                    Delete integration
                  </a>
                </Popconfirm>
              </div>
            </>
          )}
        </div>
        <div
          className="block h-20 justify-center text-center "
          onClick={() => window.open(`${URLBACK}identify/jira?id=${user.id}`)}
        >
          <IntegrationButton
            imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/communication/partners/jira_mark.png"
            text={JiraCred ? 'Installed, click to update' : 'Integrate Jira'}
          />
        </div>
        <div
          className="block h-20 justify-center text-center "
          onClick={() => {
            window.$crisp.push(['do', 'chat:open'])
          }}
        >
          <IntegrationButton
            imageSrc="https://axolo.s3.eu-west-3.amazonaws.com/media/logo/axolo_face_square.png"
            text="Request custom"
          />
        </div>
      </div>
      {provider === 'gitlab' && (
        <div className="mt-20">
          <GATTokenInput />
        </div>
      )}
    </>
  )
}
