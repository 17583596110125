import {
  GITHUB_APP_INSTALL_URL,
  URLBACK,
  GITHUB_APP_REDIRECT_URI,
} from '../../assets/urls'
import messageInteraction from '../Analytics/utils/messageInteraction'

export async function updateGitLabHook({ organizationId, axios }) {
  const url = `${URLBACK}gitlab/updateHook/${organizationId}`
  const hide = messageInteraction({ content: 'Loading...', type: 'loading', duration: 0 })
  try {
    const resp = await axios.get(url)
    if (resp.data.includes('Error') || resp.data.includes('error')) {
      throw new Error(resp.data)
    }
    console.log('resp', resp.data)

    hide()
    messageInteraction({
      content: `Successfully refreshed your webhooks.`,
      type: 'success',
    })
  } catch (error) {
    hide()
    messageInteraction({
      type: 'error',
      content: `Error updating your webhooks. Try again or contact support.${error.message}`,
    })
  }
}

export function goGithubApp(userId, authorized, selfHostedParams) {
  const githubDomain =
    selfHostedParams?.githubHostOrigin ||
    selfHostedParams?.githubDomain ||
    selfHostedParams?.githubHost
  const githubAppName = selfHostedParams?.githubAppName || selfHostedParams?.appName
  if (githubDomain?.length > 0) {
    const url = `${githubDomain}/github-apps/${githubAppName}`
    window.open(
      `${url}/installations/new?state=${userId}_githubDomain_${githubDomain}&redirect_uri=${GITHUB_APP_REDIRECT_URI}`,
      '_blank',
    )
    return
  }
  authorized
    ? window.open(`${GITHUB_APP_INSTALL_URL}/installations/new?state=${userId}`, '_blank')
    : window.location.assign(
        `${GITHUB_APP_INSTALL_URL}/installations/new?state=${userId}`,
      )
}

export function goGitlabApp({
  userId,
  authorized,
  selfHostedParams,
  uniqueIdAutoAssignReviewer,
}) {
  if (authorized) {
    window.open(
      `${URLBACK}gitlab/identify?id=${userId}&uniqueIdAutoAssignReviewer=${uniqueIdAutoAssignReviewer}`,
      '_blank',
    )
    return
  }
  let link = `${URLBACK}gitlab/identify?id=${userId}&uniqueIdAutoAssignReviewer=${uniqueIdAutoAssignReviewer}`
  if (selfHostedParams?.gitlabDomain?.length > 0) {
    link += `&gitlabDomain=${selfHostedParams.gitlabDomain}&gitlabAppId=${selfHostedParams.gitlabAppId}&gitlabAppSecret=${selfHostedParams.gitlabAppSecret}&proxy=${selfHostedParams.proxy}`
  }
  window.location.assign(link)
}
