import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App.js'
import SlackAuth from './components/AuthSlack/index.js'
import { PrivateRoute } from './components/PrivateRoute'
import Home from './pages/Home/index.js'
import PlayerNotAdmin from './pages/PlayerNotAdmin/index.js'
import Billing from './pages/Billing'
import GithubAuth from './components/AuthGithub/index.js'
import GithubInstalled from './pages/GithubInstalled/index.js'
import AuthSuccess from './pages/AuthSuccess'
import { AuthError } from './pages/AuthError'
import PullRequestChannelSettings from './pages/PullRequestChannelSettings/index.js'
import TeamChannelSettings from './pages/TeamChannelSettings/index.js'
import OpenChannels from './pages/OpenChannels/index.js'
import Team from './pages/Team'
import AdministrationPage from './pages/Administration/administration'
import { Error } from './pages/404/index.js'
import IntegrationPage from './pages/IntegrationPage/index.js'
import AnalyticsPage from './pages/Analytics/index.js'
import ProcessingInstallation from './components/Analytics/ProcessingInstallation/ProcessingInstallation.js'
import GitLabGroups from './pages/GitLabGroups/index.js'
import OnboardingInviteMembers from './pages/InviteMembersOnboarding/index.js'
import ChooseGithubOrGitlab from './pages/Onboarding/index.js'
import GitHubOnboarding from './pages/Onboarding/githubOnboarding.js'
import SlackOnboarding from './pages/Onboarding/slackOnboarding.js'
import EngineerDashboardStandup from './pages/EngineerStandup/index.js'
import StandupPage from './pages/Standups/index.js'
import EngineerDashboardTeamChannel from './pages/EngineerTeamChannel/index.js'
// import ReminderPage from './pages/Reminder/index.js'
import EngineerDashboardReminder from './pages/EngineerReminder/index.js'
import EngineerGeneral from './pages/EngineerGeneral/index.js'
import SelfHostedGitlabOnboarding from './pages/Onboarding/selfHostedGitlabOnboarding.js'
import ChooseWhichGitlabInstance from './pages/Onboarding/which-gitlab.js'
import BitbucketOnboarding from './pages/Onboarding/bitbucketOnboarding.js'
import ChooseWhichGithubInstance from './pages/Onboarding/whichGithub'
import SelfHostedGithubOnboarding from './pages/Onboarding/selfHostedGithubOnboarding.js'
import SelfOnboard from './pages/SelfOnboard/index.js'
import { Logout } from './pages/404/logout.js'
import OnboardingSelectTeamChannel from './pages/OnboardingSelectTeamChannel/index.js'
import BatchImport from './pages/BatchImport/index.js'
import { RefreshPage } from './pages/Refresh/index.js'
import NotifBuilder from './pages/NotifBuilder/index.js'
import ChooseWhichGitlabInstanceForAutoReviewRequests from './pages/GitlabInstallationAutoReviewRequests/index.js'
import RedirectionAutoReviewRequestsAvantGitlab from './pages/GitlabRedirectionAutoAssignReviewerBeforeGitlab/index.js'
import RedirectionAutoReviewRequestsAfterGitlab from './pages/GitlabRedirectionAutoAssignReviewerAfterGitlab/index.js'
import AutoAssignReviewerSettingsPage from './pages/AutoAssignReviewer/index.js'
import APIKey from './components/APIKey/index.js'

export default function Root() {
  useEffect(() => {
    window.gr =
      window.gr ||
      function () {
        ;(window.gr.q = window.gr.q || []).push(arguments)
      }
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://app.getreditus.com/gr.js?_ce=60'
    document.body.appendChild(script)

    window.gr('track', 'pageview')
  }, [])

  return (
    <div className="min-h-screen bg-gradient-to-br from-axoloBlack to-secondaryBlack">
      <Router>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/redirection-auto-reviewer-onboarding">
            <RedirectionAutoReviewRequestsAvantGitlab />
          </Route>
          <Route exact path="/auth/github/callback">
            <GithubAuth />
          </Route>
          <Route exact path="/auth/slack/callback">
            <SlackAuth />
          </Route>
          <PrivateRoute noLayout={true} exact path="/redirection-admin">
            <PlayerNotAdmin />
          </PrivateRoute>
          <Route exact path="/githubInstalled/:user">
            <GithubInstalled />
          </Route>
          <Route exact path="/player/connect/:state">
            <AuthSuccess />
          </Route>
          <Route exact path="/player/connect/github/error">
            <AuthError />
          </Route>
          <Route exact path="/player/connect/slack/error">
            <AuthError />
          </Route>
          <Route exact path="/home/refresh">
            <RefreshPage />
          </Route>
          {/* BEGIN ONBOARDING */}
          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/onboarding/selfonboard">
            <SelfOnboard />
          </PrivateRoute>
          <PrivateRoute exact path="/engineer/settings/general">
            <EngineerGeneral />
          </PrivateRoute>
          <PrivateRoute exact path="/engineer/settings/standups">
            <EngineerDashboardStandup />
          </PrivateRoute>
          <PrivateRoute exact path="/engineer/settings/team-channel">
            <EngineerDashboardTeamChannel />
          </PrivateRoute>
          <PrivateRoute exact path="/engineer/settings/reminders">
            <EngineerDashboardReminder />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding">
            <ChooseGithubOrGitlab />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/slack">
            <SlackOnboarding />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/auto-assign-reviewer">
            <ChooseWhichGitlabInstanceForAutoReviewRequests />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/auto-assign-reviewer-post-gitlab">
            <GitLabGroups autoReviewRequestOnboarding={true} />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/redirection-auto-assign-reviewer">
            <RedirectionAutoReviewRequestsAfterGitlab />
          </PrivateRoute>

          <PrivateRoute exact path="/settings/auto-assign-reviewer">
            <AutoAssignReviewerSettingsPage />
          </PrivateRoute>

          <PrivateRoute exact path="/home/onboarding/github">
            <GitHubOnboarding />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/gitlab">
            <GitLabGroups />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding-host/github">
            <ChooseWhichGithubInstance />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding-host/gitlab">
            <ChooseWhichGitlabInstance />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding-host/bitbucket">
            <BitbucketOnboarding />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding-self-hosted/gitlab">
            <SelfHostedGitlabOnboarding />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding-self-hosted/github">
            <SelfHostedGithubOnboarding />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/members">
            <OnboardingInviteMembers />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/team-channel">
            <OnboardingSelectTeamChannel />
          </PrivateRoute>
          <PrivateRoute exact path="/home/onboarding/open-channels">
            <OpenChannels finishButton />
          </PrivateRoute>
          {/* END ONBOARDING */}
          <PrivateRoute exact path="/settings/pull-request-channel">
            <PullRequestChannelSettings />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/api">
            <APIKey></APIKey>
          </PrivateRoute>
          <PrivateRoute exact path="/settings/team-channel">
            <TeamChannelSettings />
          </PrivateRoute>
          <PrivateRoute exact path="/team/batch-import">
            <BatchImport />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/billing">
            <Billing />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/billing/:status">
            <Billing />
          </PrivateRoute>
          <PrivateRoute exact path="/team/admins">
            <AdministrationPage />
          </PrivateRoute>
          <PrivateRoute exact path="/settings/standups">
            <StandupPage />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/settings/reminders">
            <ReminderPage />
          </PrivateRoute> */}
          <PrivateRoute exact path="/settings/integration">
            <IntegrationPage />
          </PrivateRoute>
          <PrivateRoute exact path="/team/team-management">
            <Team />
          </PrivateRoute>
          <PrivateRoute exact path="/open/channels">
            <OpenChannels />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics/:path">
            <AnalyticsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/analytics/processing-installation">
            <ProcessingInstallation />
          </PrivateRoute>
          <PrivateRoute exact path="/notif-builder">
            <NotifBuilder />
          </PrivateRoute>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="*">
            <Error />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}
