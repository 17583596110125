export async function fetchPullRequestsAndUpdateReducer({
  organization,
  user,
  axios,
  dispatch,
  Organization,
  isMountedRef = { current: true },
  searchQuery = '',
  // source,
}) {
  // console.log('☀️☀️ SOURCE of fetchPullRequestsAndUpdateReducer', source)
  if (
    isMountedRef.current &&
    organization.id &&
    user?.id &&
    user?.providerName !== 'autoAssignReviewer'
  ) {
    // isMountedRef.current
    try {
      // Include the search query in the API request
      const res = await axios.get(
        `/organizations/pullrequests/${organization.id}?search=${searchQuery}`,
      )
      const data = {
        pullRequests: res.data,
      }
      // we add the initial pull requests to the data if the search query is empty
      // to have a state with inital PR for RefreshPrSelectingRepo to work
      if (searchQuery === '') {
        data.initialPullRequests = res.data
      }
      console.log('data', data)
      dispatch(Organization.actions.setData(data))
      return res.data
    } catch (error) {
      console.log('Error fetching pull requests', error)
    }
  }

  return []
}

export async function fetchRepositoriesAndUpdateReducer({
  organization,
  user,
  axios,
  dispatch,
  Organization,
  isMountedRef = { current: true },
  updateRepos = true,
}) {
  if (
    isMountedRef.current &&
    organization.id &&
    user?.id &&
    user?.providerName !== 'autoAssignReviewer'
  ) {
    // isMountedRef.current
    try {
      // Fetch repositories
      const res = await axios.get(
        `organizations/repositories/${organization.id}?update=${updateRepos}`,
      )
      const data = {
        repositories: res.data,
      }
      dispatch(Organization.actions.setData(data))
      return res.data
    } catch (error) {
      console.log('Error fetching repositories', error)
    }
  }

  return []
}
